/* checkbox  */
.custom_checkbox {
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.custom_checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.custom_checkbox input:checked ~ .checkmark {
  background-color: #dc3545;
}
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
.custom_checkbox input:checked ~ .checkmark:after {
  display: block;
}
.custom_checkbox .checkmark:after {
  left: 10px;
  top: 7px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.checkmark {
  position: absolute;
  top: 0;
  right: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}
/* checkbox  */
.restaurant_images {
  width: 3rem;
  height: 3rem;
}
.black_logo {
  width: 3rem;
  height: 3rem;
}
.btn_size {
  margin-top: 24px;
}
.box {
  opacity: 1 !important;
}
.btn_add button {
  width: 50% !important;
}
.MuiDivider-root {
  border: none;
  height: 1px;
  margin: 5px 0px;
  flex-shrink: 0;
  background-color: rgba(0, 0, 0, 0.12);
  width: 100%;
}
.modal_footer {
  border-top: 0 none !important;
}
.font_weight {
  font-weight: 400 !important;
}
.form_check input {
  position: relative;
  padding-left: 0px;
}
.no-border {
  border: none !important;
}
.form-control:focus {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #be0a27 !important;
  outline: 0 !important;
  box-shadow: none !important;
}
.btn-long-width {
  width: 70%;
}
.btn-long-width:hover {
  background-color: #fff !important;
  color: #be0a27 !important;
}
.headerLogo {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-top: -0.5rem;
}
.border-top-radius {
  border-radius: 2rem;
  margin-top: 4rem;
  border: 1px solid #ddd;
}
.form_check input[type='checkbox'],
.form_check-radio input[type='radio'] {
  opacity: 1 !important;
  visibility: visible !important;
}
.modal-fontawesome-icon {
  margin-top: 0.25rem !important;
}
.form_check .form_check_label {
  padding-left: 0px !important;
}
.MuiCardMedia-media {
  width: 80px !important;
  margin: 0 auto;
  position: absolute;
  top: 40%;
  transform: translateX(50%) translateY(-50%);
  max-height: 140px;
}
.MuiTypography-h6 {
  font-weight: 400 !important;
}
@media (max-width: 576px) {
  .MuiCardMedia-media {
    width: 70px !important;
    margin: 0 auto;
  }
}

input[type='checkbox']:checked,
input[type='checkbox']:not(:checked) {
  /* position: absolute; */
  opacity: 1;
  /* pointer-events: none; */
}
.label-class {
  width: 95%;
}
.radio-class {
  width: 5%;
}
